import { useState } from 'react';

function CalcButton({ value, onButtonClick, cssClass }) {
    return (
        <button className={cssClass} onClick={onButtonClick}>
            {value}
        </button>
    )
}

// TODO - can we reuse the CalcButton but just make it wider rather than having another component?
function ZeroButton({ value, onButtonClick }) {
    return (
        <button className="zero" onClick={onButtonClick}>
            {value}
        </button>
    )
}

function CalcDisplay({ value }) {
    return <div className="calc-display">{value}</div>
}

export default function Keypad() {
    const [displayValue, setDisplayValue] = useState("0")
    const [lastValue, setLastValue] = useState("0")
    const [operation, setOperation] = useState('')

    function handleNumberClick(i) {
        var nextDisplayValue = displayValue.toString();

        // don't allow to set screen past the bound
        if (nextDisplayValue.length > 7) {
            return
        }

        nextDisplayValue = Number(nextDisplayValue + i.toString());
        setDisplayValue(nextDisplayValue);
    }

    function handleAcClick() {
        setDisplayValue(0)
        setLastValue(0)
        setOperation("")
    }

    function handlePlusMinusClick() {
        setDisplayValue(displayValue * -1)
    }

    function handlePercentClick() {
        setDisplayValue(displayValue / 100)
    }

    function handleDivideClick() {
        setLastValue(displayValue)
        setDisplayValue("0")
        setOperation("divide")
    }

    function handleMultipleClick() {
        setLastValue(displayValue)
        setDisplayValue("0")
        setOperation("multiply")
    }

    function handleMinusClick() {
        setLastValue(displayValue)
        setDisplayValue("0")
        setOperation("minus")
    }

    function handlePlusClick() {
        setLastValue(displayValue)
        setDisplayValue("0")
        setOperation("plus")
    }

    function handleDecimalPointClick() {
        var nextDisplayValue = displayValue.toString();

        // don't allow to set screen past the bound
        if (nextDisplayValue.length > 7) {
            return
        }

        nextDisplayValue = nextDisplayValue + '.';
        setDisplayValue(nextDisplayValue);
    }

    function handleEqualsClick() {
        if (operation == "divide") {
            setDisplayValue(+lastValue / +displayValue)
        }
        if (operation == "multiply") {
            setDisplayValue(+displayValue * +lastValue)
        }
        if (operation == "minus") {
            setDisplayValue(+lastValue - +displayValue)
        }
        if (operation == "plus") {
            setDisplayValue(+displayValue + +lastValue)
        }
        setOperation("")
    }

    return (
        <>
            <div className="calc-row">
                <CalcDisplay value={displayValue} />
            </div>
            <div className="calc-row">
                <CalcButton value={"AC"} onButtonClick={() => handleAcClick()} cssClass={"toprow"} />
                <CalcButton value={"+/-"} onButtonClick={() => handlePlusMinusClick()} cssClass={"toprow"} />
                <CalcButton value={"%"} onButtonClick={() => handlePercentClick()} cssClass={"toprow"} />
                <CalcButton value={"÷"} onButtonClick={() => handleDivideClick()} cssClass={`${operation == "divide" ? "operationpressed" : "operation"}`} />
            </div>
            <div className="calc-row">
                <CalcButton value={7} onButtonClick={() => handleNumberClick("7")} cssClass={"number"} />
                <CalcButton value={8} onButtonClick={() => handleNumberClick("8")} cssClass={"number"} />
                <CalcButton value={9} onButtonClick={() => handleNumberClick("9")} cssClass={"number"} />
                <CalcButton value={"×"} onButtonClick={() => handleMultipleClick()} cssClass={`${operation == "multiply" ? "operationpressed" : "operation"}`} />
            </div>
            <div className="calc-row">
                <CalcButton value={4} onButtonClick={() => handleNumberClick("4")} cssClass={"number"} />
                <CalcButton value={5} onButtonClick={() => handleNumberClick("5")} cssClass={"number"} />
                <CalcButton value={6} onButtonClick={() => handleNumberClick("6")} cssClass={"number"} />
                <CalcButton value={"-"} onButtonClick={() => handleMinusClick()} cssClass={`${operation == "minus" ? "operationpressed" : "operation"}`} />
            </div>
            <div className="calc-row">
                <CalcButton value={1} onButtonClick={() => handleNumberClick("1")} cssClass={"number"} />
                <CalcButton value={2} onButtonClick={() => handleNumberClick("2")} cssClass={"number"} />
                <CalcButton value={3} onButtonClick={() => handleNumberClick("3")} cssClass={"number"} />
                <CalcButton value={"+"} onButtonClick={() => handlePlusClick()} cssClass={`${operation == "plus" ? "operationpressed" : "operation"}`} />
            </div>
            <div className="calc-row">
                <ZeroButton value={0} onButtonClick={() => handleNumberClick("0")} cssClass={"number"} />
                <CalcButton value={"."} onButtonClick={() => handleDecimalPointClick()} cssClass={"number"} />
                <CalcButton value={"="} onButtonClick={() => handleEqualsClick()} cssClass={"operation"} />
            </div>
        </>
    );
}


/* 
    TODO: Have the AC button convert to 'C' on click and allow to clear the most recent inputs rather than reset all state
    TODO: Operation button get a thicker border to indicate last pressed
*/